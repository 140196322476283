import { Injectable } from '@angular/core';
import { STORAGE_KEY_GUEST_BOOK_READ_COUNTER } from './constants';
import { PreferencesService } from '../services/preferences.service';
import { BehaviorSubject } from 'rxjs';

export const GUEST_START_READING_LOGIN_PROMPT = 3; // Interval at which the user is optionally asked to create an account

@Injectable({ providedIn: 'root' })
export class GuestBookReadCounterState {
  constructor(private preferenceService: PreferencesService) {}

  private readonly STORAGE_KEY = STORAGE_KEY_GUEST_BOOK_READ_COUNTER;

  counter$ = new BehaviorSubject<number>(0);

  async initialize(): Promise<void> {
    const counter = await this.get();
    counter ? this.counter$.next(counter) : await this.set(0);
  }

  async set(counter: Partial<number>): Promise<void> {
    this.counter$.next(counter);
    await this.preferenceService.set(this.STORAGE_KEY, JSON.stringify(counter));
  }

  private async get(): Promise<number | null> {
    return JSON.parse(<string>(await this.preferenceService.get(this.STORAGE_KEY)).value);
  }
}
